import React from 'react';

import styled from 'styled-components';

import background from './assets/img/background.jpg';


function App() {

  const Container = styled.div`
    background:url(${background});
    background-position: center;
    background-size:cover;
    height: 100vh;
    

    display: flex;
    /* flex-direction: column;
    justify-content: center; */
    align-items: center;
  `;

  const Title = styled.div`
    font: 400 2rem 'Pacifico', sans-serif;
    color: white;
    position: absolute;
    top: 2rem;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  `;

  const Content = styled.div`
    background-color: rgba(0,0,0,0.65);
    color: white;
    padding: 2rem;
    padding-left:4rem;

    border-radius: 0 10px 10px 0;

    h1 {
      font: 400 3.75rem 'Coustard', sans-serif;
      line-height: 70px;
      padding: 0;
      margin: 0;
    }

    h5 {
      font: 400 1.5rem 'Coustard', sans-serif;
      margin: 0;
      color:#b7b7b7;
    }
  `;

  return (
    <Container>
      <Title>Na Caixinha</Title>
      <Content>
        <h1>Bem Vindo</h1>
        <h5>Estamos em construção!</h5>
      </Content>
    </Container>
  );
}

export default App;

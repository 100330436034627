import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import firebase from 'firebase/app'
import 'firebase/analytics';

firebase.initializeApp({
  apiKey: "AIzaSyCwhgUQFLk8Vekyz7srRpe_gj5aJmPDCUc",
  authDomain: "na-caixinha.firebaseapp.com",
  projectId: "na-caixinha",
  storageBucket: "na-caixinha.appspot.com",
  messagingSenderId: "956670493277",
  appId: "1:956670493277:web:118fde239a6a221c7c03b4",
  measurementId: "G-E3P44C3JZK"
})
firebase.analytics()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
